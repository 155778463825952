export const environment = {
  env: 'DEVELOP',
  cookieDomain: 'maplix.com',
  production: false,
  engage: 'https://dev.engage.maplix.com/',
  account: 'https://dev.account.maplix.com/',
  explore: 'https://dev.explore.maplix.com/',
  api: `https://dev.api.maplix.com/`,
  stitchId: 'maplix-account-dev-vkgph',
  geoserver: 'https://geo.maplix.com/geoserver/',
  geoserverDb: 'maplix-dev',
  mapboxToken: 'pk.eyJ1IjoiY2VkcmljdmVyc2x1eXMiLCJhIjoiY2s5azFwaTFvMDg5czNsbnNxY2ExbHgyeSJ9.W_Tk-JA9ujNEL55b_cf7Hw',
  plausible: {
    domain: 'dev.explore.maplix.com',
  },
};
